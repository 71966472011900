import { GET_NOTIFICACIONES } from "../../types"
import clienteAxios from "../../../Utils/axios"

export function ConsultaNotificacionesF() {
  return async dispatch => {
    try {
      const tokenaccess = localStorage.getItem("token")
      const rutsession = localStorage.getItem("rut")
      const dvsession = localStorage.getItem("dv")
      const dataForm = new FormData()
      dataForm.append("run", rutsession)
      dataForm.append("dv", dvsession)

      const respuesta = await clienteAxios.post("Notificacion/", dataForm, {
        headers: {
          Authorization: "Bearer " + tokenaccess,
          accept: "application/json",
        },
      })

      if (respuesta.data !== "") {
        dispatch(Get_Notificaciones_F(respuesta.data.notificaciones))
      } else {
        dispatch(Get_Notificaciones_F([]))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const Get_Notificaciones_F = datos => ({
  type: GET_NOTIFICACIONES,
  payload: datos,
})
