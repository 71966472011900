import React, { useEffect } from "react"
import { ConsultaNotificacionesF } from "../../state/actions/Notificaciones/NotificacionesActions"
import { useSelector, useDispatch } from "react-redux"
const NotificacionesComponent = () => {
  const dispatch = useDispatch()

  const Notificaciones = useSelector(
    state => state.Notificaciones.Notificaciones
  )
  useEffect(() => {
    if (Notificaciones.length === 0) {
      const ConsultaNotificaciones = () => dispatch(ConsultaNotificacionesF())

      ConsultaNotificaciones()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="col-12 d-flex">
        <h2 className="mx-auto primary-text">
          Aquí podrás encontrar todas tus notificaciones
        </h2>
      </div>

      <div className="container ">
        <div className="row ">
          <div className="col-8 col-sm-4 col-lg-3 form-control-sm my-3">
            <input
              className="form-control"
              type="text"
              placeholder="Filtrar Tipo Notificación"
              id="buscadorTipoNotificacion"
            ></input>
          </div>

          <div className="col-12 mt-2 px-0">
            <div className="table-responsive ">
              <table className="table table-striped">
                <tbody>
                  <>
                    <tr className="bg-table-modal-ss">
                      <th scope="row" className="text-light">
                        Eliminar
                      </th>
                      <th className="text-light">Tipo Notificación</th>
                      <th className="text-light">Fecha Notificación</th>
                      <th className="text-light">Descripción</th>
                      <th className="text-light">Quitar Todos</th>
                      <th className="text-light">Estado</th>
                    </tr>
                    <tr>
                      <td scope="row">SOC </td>
                      <td>202006-202006</td>
                      <td>$59.443</td>
                      <td>$59.443</td>
                      <td>$59.443</td>
                      <td>$59.443</td>
                    </tr>
                    <tr>
                      <td scope="row">SOC </td>
                      <td>202006-202006</td>
                      <td>$59.443</td>
                      <td>$59.443</td>
                      <td>$59.443</td>
                      <td>$59.443</td>
                    </tr>
                  </>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotificacionesComponent
