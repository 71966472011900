import React, {useEffect} from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import NotificacionesComponent from "../../components/NotificacionesComponents/Notificaciones"
import LayoutProvider from "../../../src/components/Layout"
import { navigate } from 'gatsby';

const NotificacionesPage = ({ location }) => {
  useEffect(() => {
    const logueado = localStorage.getItem("logueado")
    const requiere_actualizar = localStorage.getItem("requiere_actualizar")

    if (logueado === false || logueado === null || logueado === undefined) {
        navigate("/")
    } else if (requiere_actualizar === true || requiere_actualizar === "true") {
        navigate("/inicio/editar-perfil/")
    }
  }, [])

  return (
    <LayoutProvider location={location}>
      <p className="title-dashboard-ss">Lista Notificaciones </p>
      <Container fluid className="container-dashboard-ss">
        <Row>
          <NotificacionesComponent />
        </Row>
      </Container>
    </LayoutProvider>
  )
}

export default NotificacionesPage
